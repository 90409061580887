import ToastBox from 'ui-kit/toast-box/toast-box';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type AlertConfig = Record<string, { icon: string; variant: string; translation?: string }>;

/**
 * Renders an alert component based on the provided type and localesBaseKey.
 * Currently, only 'success' and 'error' types are supported.
 *
 * @param {string} type - The type of the alert component.
 * @param {string} localesBaseKey - The base key for the translation locales.
 * @returns {ReactElement} The rendered alert component.
 */
const AlertComponent = ({ type, localesBaseKey }: { type: string; localesBaseKey?: string }) => {
    const { t } = useTranslation();
    const localesBase = localesBaseKey || 'components.membership.membershipPaymentMethodForm';

    const alertConfig: AlertConfig = {
        success: { icon: 'success', variant: 'success', translation: 'infoAlertSuccess' },
        error: { icon: 'danger', variant: 'danger', translation: 'infoAlertError' }
    };

    const { icon, variant, translation }: any = alertConfig[type] || {};

    return (
        <ToastBox icon={icon} variant={variant}>
            {t(`${localesBase}.${translation}`)}
        </ToastBox>
    );
};

export default AlertComponent;
