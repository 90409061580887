import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { forwardRef, useEffect, useRef } from 'react';

// ui-kit
import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';

// components
import AlertComponent from 'display-components/membership/alert-messages/promo-code';

// Schemas
import { MEMBERSHIP_PROMO_CODE_SCHEMA } from 'schema/membership/payment-method.schema';

// Types
import { MembershipPaymentMethodFormPromoCodeProps } from 'types/membership';

// Hooks
import { useGlobalLink } from 'hooks/useGlobalLink';

// styles
import './membership-payment-form-promo-code.style.scss';

const MembershipPaymentFormPromoCode = forwardRef(
    ({ localesBaseKey, onSubmit }: MembershipPaymentMethodFormPromoCodeProps, ref) => {
        const { t } = useTranslation();
        const inputRef = useRef(null);

        const globalLink = useGlobalLink();

        const formName = 'Promo Code';

        if (!localesBaseKey) {
            localesBaseKey = 'forms.membership.paymentMethod';
        }

        useEffect(() => {
            globalLink.setFormName(formName);
            globalLink.setFlowName('MembershipRegistrationPaymentMethod');
            globalLink.setStepName(t('registration.paymentMethodHeading'));
        }, [globalLink, t]);

        const handleReset = () => {
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        };

        return (
            <div className="membership-payment-form-promo-code-promo-code-container">
                {/* @TODO: DRX-2305: Apply promo code. */}
                {/* Promo Code */}
                <Formik
                    innerRef={ref as React.MutableRefObject<FormikProps<{ promoCode: string }>>}
                    onSubmit={onSubmit}
                    validateOnChange={true}
                    validateOnBlur={false}
                    initialValues={{
                        promoCode: ''
                    }}
                    validationSchema={MEMBERSHIP_PROMO_CODE_SCHEMA}
                    enableReinitialize={true}
                    onReset={handleReset}
                >
                    {({ values, touched, errors, handleChange, handleSubmit, isSubmitting }) => {
                        return (
                            <Form
                                id="membership-payment-form-promo-code-promo-code"
                                data-ga-form-name={formName}
                                onSubmit={handleSubmit}
                                autoComplete="off"
                                className="membership-payment-form-promo-code-promo-code"
                            >
                                <div className="membership-payment-form-promo-code__sections-promo-code membership-payment-form-promo-code__sections__item">
                                    <div className="membership-payment-form-promo-code__sections-promo-code--label">
                                        {t(`${localesBaseKey}.promoCode`)}
                                    </div>

                                    <div className="membership-payment-form-promo-code__sections-promo-code--field">
                                        <div
                                            className={`membership-payment-form-promo-code__sections-promo-code--input ${
                                                errors?.promoCode ? 'has-error' : ''
                                            }`}
                                        >
                                            <Text
                                                inputRef={inputRef}
                                                name="promoCode"
                                                label={t(`${localesBaseKey}.labels.promoCode`)}
                                                onChange={handleChange}
                                                touched={touched.promoCode}
                                                value={values?.promoCode}
                                                onFocus={() =>
                                                    globalLink.handleFieldFocus(t(`${localesBaseKey}.promoCode`))
                                                }
                                                className="grid--input"
                                                disabled={errors?.promoCode === 'valid'}
                                            />

                                            {errors?.promoCode && (
                                                <div className="membership-payment-form-promo-code__sections-promo-code--info grid--alert">
                                                    <AlertComponent
                                                        type={errors.promoCode === 'valid' ? 'success' : 'error'}
                                                        localesBaseKey="components.membership.membershipPaymentMethodForm"
                                                    />
                                                </div>
                                            )}

                                            <Button
                                                async
                                                className="sm-full grid--button"
                                                label={t(`${localesBaseKey}.ctas.apply`)}
                                                variant="outline-primary"
                                                type="submit"
                                                dataGAFormName={formName}
                                                dataGAFormStep="Step4"
                                                dataGAFormStepName="PromoCode"
                                                disabled={
                                                    isSubmitting ||
                                                    !values?.promoCode.length ||
                                                    errors?.promoCode === 'valid'
                                                }
                                                isBusy={isSubmitting}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        );
    }
);

export default MembershipPaymentFormPromoCode;
