import './stepper.scss';

export interface StepperProps {
    steps: Array<string>;
    activeStep: number;
}

const getStepClass = (step: Number, activeStep: Number) => {
    if (activeStep >= step) {
        return 'active';
    }
    return 'inactive';
};

/**
 * Renders a stepper component with a series of steps.
 * @param steps {string[]} - An array of strings representing the steps in the stepper.
 * @param activeStep {number} - The currently active step.
 * @returns {JSX.Element} The rendered stepper component.
 */
function Stepper({ steps, activeStep }: StepperProps) {
    return (
        <div className="steps-container">
            {steps.map((label, index) => (
                <div key={`${index}-step`} className={`step ${getStepClass(index, activeStep)}`} />
            ))}
        </div>
    );
}

export default Stepper;
