import { useState } from 'react';

import Tabs from './tabs';

interface TabListProps {
    tabs: string[];
    activeTab: string;
    onChangeTab?: (tab: string) => void;
}

const TabList: React.FC<TabListProps> = ({ tabs, activeTab, onChangeTab }) => {
    const [activatedTab, setActivatedTab] = useState(activeTab || '');

    const handleTabItemClick = (tab: string) => {
        setActivatedTab(tab);
        if (onChangeTab) {
            onChangeTab(tab);
        }
    };

    return (
        <div className="tab-list">
            <Tabs tabs={tabs} activeTab={activatedTab} onTabChange={handleTabItemClick} />
        </div>
    );
};

export default TabList;
