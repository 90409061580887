import { Field, Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import { Checkbox } from 'ui-kit/checkbox';
import FormSelect from 'ui-kit/form-select/form-select';
// ui-kit
import Text from 'ui-kit/text/text';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { creditCardMonthOptions, getExpYears } from 'const/options';

// Schemas
import { MEMBERSHIP_PAYMENT_METHOD_SCHEMA } from 'schema/membership/payment-method.schema';

// Types
import { MembershipPaymentMethodFormProps, MembershipPaymentMethodFormValues } from 'types/membership';

// Hooks
import { useGlobalLink } from 'hooks/useGlobalLink';

// styles
import './membership-payment-method-form.style.scss';

const MembershipPaymentForm = forwardRef(
    (
        {
            state,
            onSubmit,
            localesBaseKey,
            onShowingAlternateBilling,
            membershipPlanId
        }: MembershipPaymentMethodFormProps,
        ref
    ) => {
        const { t } = useTranslation();

        const globalLink = useGlobalLink();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
        const [isShowingAlternateBilling, setShowingAlternateBilling] = useState<boolean>(false);

        const expYears = useMemo(() => getExpYears({ dates: 9 }), []);

        const formName = 'Payment Method';

        if (!localesBaseKey) {
            localesBaseKey = 'forms.membership.paymentMethod';
        }

        useEffect(() => {
            globalLink.setFormName(formName);
            globalLink.setFlowName('MembershipRegistrationPaymentMethod');
            globalLink.setStepName(t('registration.paymentMethodHeading'));
        }, [globalLink, t]);

        const handleShowingAlternateBilling = (isChecked: boolean) => {
            setShowingAlternateBilling(isChecked);
            onShowingAlternateBilling(isChecked);
        };

        return (
            <div className="membership-payment-form-container">
                <Formik
                    onSubmit={onSubmit}
                    validationSchema={MEMBERSHIP_PAYMENT_METHOD_SCHEMA}
                    innerRef={ref as React.MutableRefObject<FormikProps<Partial<MembershipPaymentMethodFormValues>>>}
                    initialValues={{
                        nameOnCard: state?.nameOnCard,
                        cardNumber: state?.cardNumber,
                        cardExpiration: state?.cardExpiration,
                        expMonth: state?.expMonth,
                        expYear: state?.expYear,
                        cvcNumber: state?.cvcNumber,
                        fsaPaymentMethod: state?.fsaPaymentMethod,
                        alternateBillingAddress: state?.alternateBillingAddress
                    }}
                >
                    {({ values, touched, errors, handleChange, handleSubmit, setFieldValue }) => (
                        <Form
                            id="membership-payment-form"
                            data-ga-form-name={formName}
                            onSubmit={handleSubmit}
                            autoComplete="off"
                            className="membership-payment-form"
                        >
                            <div className="membership-payment-form__sections-header">
                                <div>
                                    <div className="membership-payment-form__sections-header-pill"></div>
                                    <div>{t('registration.paymentMethodHeading')}</div>
                                </div>
                            </div>
                            {membershipPlanId === 3 && (
                                <ToastBox variant="info" icon="info">
                                    {t('membership.selectPlan.telemedicinePayment')}
                                </ToastBox>
                            )}

                            {/* Payment Method */}
                            <div className="membership-payment-form__sections">
                                <div className="membership-payment-form__sections-payment-info membership-payment-form__sections__item">
                                    {/* Name on Card */}
                                    <div className="membership-payment-form__sections-payment-info">
                                        <Text
                                            name="cardNumber"
                                            label={t(`${localesBaseKey}.labels.cardNumber`)}
                                            onChange={handleChange}
                                            errors={
                                                errors?.cardNumber
                                                    ? t(`${localesBaseKey}.errors.cardNumber`)
                                                    : undefined
                                            }
                                            touched={touched.cardNumber}
                                            value={values?.cardNumber}
                                            onFocus={() =>
                                                globalLink.handleFieldFocus(t(`${localesBaseKey}.cardNumber`))
                                            }
                                        />
                                        <Text
                                            name="nameOnCard"
                                            label={t(`${localesBaseKey}.labels.nameOnCard`)}
                                            onChange={handleChange}
                                            errors={
                                                errors?.nameOnCard
                                                    ? t(`${localesBaseKey}.errors.nameOnCard`)
                                                    : undefined
                                            }
                                            touched={touched.nameOnCard}
                                            value={values?.nameOnCard}
                                            onFocus={() =>
                                                globalLink.handleFieldFocus(t(`${localesBaseKey}.nameOnCard`))
                                            }
                                        />
                                    </div>

                                    {/* CC Number, Expiration Date, CVC */}
                                    <div className="membership-payment-form__sections-card-info">
                                        <Field
                                            name="expMonth"
                                            component={FormSelect}
                                            options={creditCardMonthOptions}
                                            placeholder={t(`${localesBaseKey}.labels.expMonth`)}
                                            errors={
                                                errors?.expMonth
                                                    ? errors?.expMonth === 'expMonth is a required field'
                                                        ? t(`${localesBaseKey}.errors.expMonth`)
                                                        : t(`forms.errorMessages.invalidMonth`)
                                                    : undefined
                                            }
                                            touched={touched.expMonth}
                                            onFocus={() => globalLink.handleFieldFocus(t(`${localesBaseKey}.expMonth`))}
                                            value={values?.expMonth}
                                        />

                                        <Field
                                            name="expYear"
                                            component={FormSelect}
                                            options={expYears}
                                            placeholder={t(`${localesBaseKey}.labels.expYear`)}
                                            errors={errors?.expYear ? t(`${localesBaseKey}.errors.expYear`) : undefined}
                                            touched={touched.expYear}
                                            onFocus={() => globalLink.handleFieldFocus(t(`${localesBaseKey}.expYear`))}
                                            value={values?.expYear}
                                        />

                                        <Text
                                            name="cvcNumber"
                                            label={t(`${localesBaseKey}.labels.cvcNumber`)}
                                            onChange={handleChange}
                                            errors={
                                                errors?.cvcNumber ? t(`${localesBaseKey}.errors.cvcNumber`) : undefined
                                            }
                                            touched={touched.cvcNumber}
                                            value={values?.cvcNumber}
                                            onFocus={() =>
                                                globalLink.handleFieldFocus(t(`${localesBaseKey}.cvcNumber`))
                                            }
                                        />
                                    </div>

                                    <Checkbox
                                        label={t(`${localesBaseKey}.fsaLabel`)}
                                        id="fsaPaymentMethod"
                                        name="fsaPaymentMethod"
                                        onClick={(isChecked: boolean) => {}}
                                        onCheckChanged={(isChecked: boolean) => {
                                            setFieldValue('fsaPaymentMethod', isChecked);
                                        }}
                                        className="checkbox-fsa-method"
                                        defaultValue={values.fsaPaymentMethod}
                                    />

                                    <Checkbox
                                        label={t(`${localesBaseKey}.billingAddress`)}
                                        id="alternateBillingAddress"
                                        name="alternateBillingAddress"
                                        onClick={(isChecked: boolean) => {}}
                                        onCheckChanged={(isChecked: boolean) => {
                                            setFieldValue('alternateBillingAddress', isChecked);
                                            handleShowingAlternateBilling(isChecked);
                                        }}
                                        className="checkbox-alternate-billing-method"
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
);

export default MembershipPaymentForm;
