// Tabs.tsx

import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import './tabs.style.scss';

interface TabsProps {
    tabs: string[];
    activeTab: string;
    onTabChange: (tab: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ tabs, activeTab, onTabChange }) => {
    const { t } = useTranslation();

    // TODO: Potentially could support add'l tab styles: [ "tab-list", "tab-slider" ]
    return (
        <div className="tabs">
            <ul className="tab-buttons align-center" role="tablist">
                {tabs.map((tab) => (
                    <li
                        key={tab}
                        className={classNames('tab-button', { active: tab === activeTab })}
                        aria-hidden="true"
                        role="tab"
                        onClick={() => onTabChange?.(tab)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.key === 'Space') {
                                onTabChange?.(tab);
                            }
                        }}
                    >
                        <span className="tab-button--text desktop">
                            {t(`components.membership.membershipPlanTabs.desktop.${tab}`)}
                        </span>
                        <span className="tab-button--text mobile">
                            {t(`components.membership.membershipPlanTabs.mobile.${tab}`)}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Tabs;
