import valid from 'card-validator';
import moment from 'moment';
import * as yup from 'yup';

const isValidCardType = (cardNumber: string): boolean => {
    const cardTypes = [
        /^4[0-9]{12}(?:[0-9]{3})?$/,
        /^5[1-5][0-9]{14}$/,
        /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        /^3[47][0-9]{13}$/
    ];
    return cardTypes.map((cardType) => cardType.test(cardNumber)).includes(true);
};

export const MEMBERSHIP_PAYMENT_METHOD_SCHEMA = yup.object().shape({
    cardNumber: yup
        .string()
        .required()
        .min(13)
        .max(19)
        .test(
            'test-number', // this is used internally by yup
            (value) => valid.number(value).isValid
        )
        .test(
            'test-number', // this is used internally by yup
            (value) => value !== undefined && isValidCardType(value)
        ),
    nameOnCard: yup
        .string()
        .required()
        .test('test-name', (value) => valid.cardholderName(value).isValid),
    expMonth: yup
        .string()
        .required()
        .test('test-month', (value, { parent }) => {
            // avoid overlaping with "required" validation
            if (value === undefined) return false;

            const currentDate = moment();
            const cardDateExpiration = moment(`${value}, ${parent.expYear || currentDate.get('YYYY')}`, 'MMMM, YYYY');
            const isCardExpirated = currentDate.isBefore(cardDateExpiration);

            return isCardExpirated;
        }),
    expYear: yup
        .string()
        .required()
        .test('test-eyar', (value) => valid.expirationYear(value).isValid),
    cvcNumber: yup
        .string()
        .required()
        .test('cvc-number', (value) => valid.cvv(value, [3, 4]).isValid)
});

export const MEMBERSHIP_PROMO_CODE_SCHEMA = yup.object().shape({
    promoCode: yup.string()
});
