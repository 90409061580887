import classNames from 'classnames';

import Link from 'ui-kit/link/link';

import {
    WorkflowFooterLinksItem,
    WorkflowFooterLinksParsed,
    WorkflowFooterLinksProps
} from './workflow-footer-links.props';
import './workflow-footer-links.styles.scss';

export const parseFooterLinks = (link: {
    title: string;
    uri: string;
}): Omit<WorkflowFooterLinksParsed, 'locationFlow'> => {
    const titleParts = link.title.split(/<span>|<\/span>/);
    const label = titleParts[0].trim();
    const labelLink = titleParts.length > 1 ? titleParts[1].trim() : '';
    const isExternalUrl = link.uri.startsWith('https');
    const url = isExternalUrl ? link.uri : link.uri.replace('internal:', '');

    return {
        label,
        labelLink,
        url,
        isExternalUrl
    };
};

const FooterLinkItem = ({ label, labelLink, url, isExternalUrl, locationFlow }: WorkflowFooterLinksParsed) => {
    const mainClass = classNames('workflow-footer-link', { isMailOrderFlow: locationFlow.match(/mail-order/gi) });

    return (
        <div className={mainClass}>
            {label}
            <Link
                to={url}
                label={labelLink}
                variant="underline-blue-bold"
                external={isExternalUrl}
                dataGALocation={locationFlow}
                hasExternalIcon={false}
            />
        </div>
    );
};

const renderFooterLinks = (links: WorkflowFooterLinksItem[], locationFlow: string): JSX.Element[] => {
    return links.reduce<JSX.Element[]>((acc, link, index) => {
        const parsedLink = parseFooterLinks(link);
        acc.push(<FooterLinkItem key={`link-${index}`} {...parsedLink} locationFlow={locationFlow} />);
        if (index < links.length - 1) {
            acc.push(<div className="divider" key={`divider-${index}`} />);
        }
        return acc;
    }, []);
};

const WorkflowFooterLinks = ({ links, locationGAflow }: WorkflowFooterLinksProps) => {
    const renderLinks = renderFooterLinks(links, locationGAflow);
    const isSingleLink = links && links?.length === 1;
    const mainClass = classNames('workflow-footer-links-container', { 'single-link': isSingleLink });

    return <div className={mainClass}>{renderLinks}</div>;
};

export default WorkflowFooterLinks;
